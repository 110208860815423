<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                OSD
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                SPRZEDAŻ ENERGII ELEKTRYCZNEJ
              </p>
            </v-col>
            <v-container class="d-inline-flex">
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <!-- <v-card-title>
                    SAFE INVESTMENTS FOR THE TIMES
                  </v-card-title> -->
                  <v-card-text>
                    <p>
                      Sprzedaż energii elektrycznej to nasza podstawowa działalność. Zaopatrujemy w energię elektryczną kilkanaście podmiotów gospodarczych działających na terenie przyległym do Europejskiego Konsorcjum Kolejowego WAGON Sp. z o.o. Działamy na podstawie:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/48-wniosek-rejestr-mee-osoba-prawna.pdf"
                          target="_blank"
                        >
                          Wniosek o wpis do rejestru magazynów energii elektrycznej dla osoby prawnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/49-wniosek-rejestr-mee-osoba-fizyczna.pdf"
                          target="_blank"
                        >
                          Wniosek o wpis do rejestru magazynów energii elektrycznej dla osoby fizycznej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/50-rejestr-mee_przylaczonych-do-sieci-EW.pdf"
                          target="_blank"
                        >
                          Rejestr magazynów energii elektrycznej przyłączonych do sieci przesyłowej Energetyki Wagon
                        </a>
                      </li>
                      <!-- <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/45-art7ust8l-ustawyPrawoEnergetyczne.pdf"
                          target="_blank"
                        >
                          art. 7 ust. 8l Ustawy Prawo Energetyczne
                        </a>
                      </li> -->
                      <!-- <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/44-umowyGeneralne-zawarte-przez-Energetyke-Wagon.pdf"
                          target="_blank"
                        >
                          Umowy Generalne zawarte przez Energetykę Wagon – stan aktualny na dzień 04.10.2023 r.
                        </a>
                      </li> -->
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/43-URE_OSD_2022.pdf"
                          target="_blank"
                        >
                          Decyzja o wyznaczeniu Przedsiębiorstwa operatorem systemu dystrybucyjnego elektroenergetycznego uzyskanej 29.04.2022 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/42-URE_OSD.pdf"
                          target="_blank"
                        >
                          Decyzja o wyznaczeniu Przedsiębiorstwa operatorem systemu dystrybucyjnego elektroenergetycznego.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/41-obrot_2022.pdf"
                          target="_blank"
                        >
                          Koncesja na obrót energią elektryczną uzyskana 30.03.2022 r. decyzją Prezesa Urzędu Regulacji Energetyki nr OEE/470/15714/W/OPO/2007/AJ
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/40-dystrybucja_2022.pdf"
                          target="_blank"
                        >
                          Koncesja na dystrybucję energii elektrycznej uzyskana 30.03.2022 r. decyzją Prezesa Urzędu Regulacji Energetyki nr DEE/38/15714/W/OPO/2007/AJ
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/39-obrot.pdf"
                          target="_blank"
                        >
                          Koncesja na obrót energią elektryczną uzyskana 26.04.2007 r. decyzją Prezesa Urzędu Regulacji Energetyki nr OEE/470/15714/W/OPO/2007/AJ
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/38-dystrybucja.pdf"
                          target="_blank"
                        >
                          Koncesja na dystrybucję energii elektrycznej uzyskana 26.04.2007 r. decyzją Prezesa Urzędu Regulacji Energetyki nr DEE/38/15714/W/OPO/2007/AJ
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/51-EnergetykaWagon-01052024-dystrybucja.pdf"
                          target="_blank"
                        >
                          Taryfa dla energii elektrycznej w zakresie dystrybucji energii elektrycznej wchodząca w życie od dnia 1 maja 2024 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/37-Energetyka_Wagon-taryfa-2021.pdf"
                          target="_blank"
                        >
                          Taryfa dla energii elektrycznej w zakresie dystrybucji energii elektrycznej wchodząca w życie od dnia 1 czerwca 2021 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/36-Taryfa-Energetyka-Wagon-2019.pdf"
                          target="_blank"
                        >
                          Taryfa dla energii elektrycznej w zakresie dystrybucji energii elektrycznej wchodząca w życie od dnia 1 stycznia 2020 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/35-zmiana-taryfy_2022-03.pdf"
                          target="_blank"
                        >
                          Zmiana taryfy dla energii elektrycznej wchodzącej w życie od dnia 1 kwietnia 2022 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/34-zmiana-taryfy_2022-10.pdf"
                          target="_blank"
                        >
                          Zmiana taryfy dla energii elektrycznej wchodzącej w życie od dnia 1 listopada 2022 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/33-zmiana-taryfy_2023-01.pdf"
                          target="_blank"
                        >
                          Zmiana taryfy dla energii elektrycznej wchodzącej w życie od dnia 1 lutego 2023 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/32-zmiana-taryfy_2023-03.pdf"
                          target="_blank"
                        >
                          Zmiana taryfy dla energii elektrycznej wchodzącej w życie od dnia 15 marca 2023 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/31-cennik_2022-10.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 października 2022 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/30-cennik_2022-01.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 stycznia 2022 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/29-cennik_2020-07.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 lipca 2020 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/28-cennik_2018-10.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 października 2018 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/27-cennik_2019-07.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 lipca 2019 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/26-cennik_2019-08.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 sierpnia 2019 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/25-cennik_2015-01.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 stycznia 2019 r. do dnia 30 czerwca 2019 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/24-cennik_2015-01.pdf"
                          target="_blank"
                        >
                          Cennik sprzedaży energii elektrycznej obowiązujący od dnia 1 lipca 2019 r. do dnia 31 grudnia 2019 r. dla klientów o których mowa w Art. 5 ust. 1a i 1b ustawy z dnia 28 grudnia 2018 r. o zmianie ustawy o podatku akcyzowym oraz niektórych innych ustaw
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/23-ogolne-warunki-sprzedazy-energii.pdf"
                          target="_blank"
                        >
                          Ogólne warunki sprzedaży energii elektrycznej i świadczenia usług przesyłowych
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/22-IRiESD_2020.pdf"
                          target="_blank"
                        >
                          Instrukcja Ruchu i Eksploatacji Sieci Dystrybucyjnej zatwierdzona dnia 7.01.2020 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/21-IRiESD_2015.pdf"
                          target="_blank"
                        >
                          Instrukcji Ruchu i Eksploatacji Sieci Dystrybucyjnej zatwierdzona dnia 25.06.2015 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/20-K_A_IRiESD.pdf"
                          target="_blank"
                        >
                          Karta aktualizacji IRiESD nr 1/2017
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/47-wskazniki_czasu_trwania-przerw_2023.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2023
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/19-wskazniki_czasu_trwania-przerw_2022.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2022
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/18-wskazniki_czasu_trwania-przerw_2021.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2021
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/17-wskazniki_czasu_trwania-przerw_2020.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2020
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/16-wskazniki_czasu_trwania-przerw_2019.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2019
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/15-wskazniki_czasu_trwania-przerw_2018.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2018
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/14-wskazniki_czasu_trwania-przerw_2017.pdf"
                          target="_blank"
                        >
                          Wskaźniki czasu trwania przerw w dostarczaniu energii elektrycznej za rok 2017
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/13-1-art7-ust-8l-ustawy-Prawo-energetyczne.pdf"
                          target="_blank"
                        >
                          Informacja dotycząca źródeł wytwórczych energii elektrycznej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/12-1-umowy-generalne-zawarte-przez-Energetyke-Wagon.pdf"
                          target="_blank"
                        >
                          Umowy Generalne zawarte przez Energetykę Wagon – stan aktualny na dzień 02.10.2024 r.
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/46-sprzedawca-zobowiazany.pdf"
                          target="_blank"
                        >
                          Sprzedawca zobowiązany na obszarze działania OSDn wyznaczony na 2024 rok
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/11-sprzedawca-zobo_na-2023.pdf"
                          target="_blank"
                        >
                          Sprzedawca zobowiązany na obszarze działania OSDn wyznaczony na 2023 rok
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/10-sprzedawca-zobo_na-2022.pdf"
                          target="_blank"
                        >
                          Sprzedawca zobowiązany na obszarze działania OSDn wyznaczony na 2022 rok
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/9-sprzedawca-zobo_na-2021.pdf"
                          target="_blank"
                        >
                          Sprzedawca zobowiązany na obszarze działania OSDn wyznaczony na 2021 rok
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/8-informacja-o-sprzedawcy-z-urzedu.pdf"
                          target="_blank"
                        >
                          Informacja o sprzedawcy z urzędu
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/7-sprzedawca-rezerwowy.pdf"
                          target="_blank"
                        >
                          Informacja o sprzedawcy rezerwowym
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/6-ogolne-wymogi-przylaczania-jednostek-wytworczych.pdf"
                          target="_blank"
                        >
                          Ogólne wymogi przyłączania jednostek wytworczych
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/5-decyzja_umorzenie.pdf"
                          target="_blank"
                        >
                          Decyzja Prezesa URE dotycząca zatwierdzenia wymogów ogólnego stosowania dla przyłączania do sieci systemów WN prądu stałego oraz modułów parku energii z podłączeniem prądu stałego zgodne z rozporządzeniem komisji (UE) 2016/1447
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/4-ecyzja_umorzenie_2.pdf"
                          target="_blank"
                        >
                          Decyzja Prezesa URE dotycząca zatwierdzenia wymogów ogólnego stosowania dla przyłączania odbioru, zgodnie z art. 6 ust. 1 rozporządzenia komisji (UE) 2016/1388
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/3-decyzja-zatwierdzenie.pdf"
                          target="_blank"
                        >
                          Decyzja Prezesa URE dotycząca zatwierdzenia wymogów ogólnego stosowania dla przyłączania jednostek wytwórczych do sieci, zgodnie z art. 7 ust. 4 rozporządzenia komisji (UE) 2016/631
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/2-ozporzadzenie-wzor-oswiadczenia.pdf"
                          target="_blank"
                        >
                          Rozporządzenie Ministra Klimatu i Środowiska z dnia 10 listopada 2022 roku w sprawie wzoru oświadczenia odbiorcy uprawnionego
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/osd-sprzedaz-energii/1-oswiadczenie-wzor.pdf"
                          target="_blank"
                        >
                          Wzór oświadczenia odbiorcy uprawnionego
                        </a>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-text>
                    <p>
                      DOKUMENTY DO POBRANIA:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/19-wniosek_o_przylaczenie.pdf"
                          download
                        >
                          Wniosek o przyłączenie
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/18-Wniosek_o_zawarcie_umowy1.pdf"
                          download
                        >
                          Wniosek o zawarcie umowy dla odbiorców zakwalifikowanych do II, III, IV i VI grupy przyłączeniowej powyżej 40 kW
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/17-Wniosek_o_zawarcie_umowy2.pdf"
                          download
                        >
                          Wniosek o zawarcie umowy dla odbiorców zakwalifikowanych do V i VI grupy przyłączeniowej do 40 kW
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/16-Wzor-umowy-o-swiadczenie-uslug-dystrybucji-energii-elektrycznej.pdf"
                          download
                        >
                          Wzór umowy o świadczenie usług dystrybucji energii elektrycznej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/15-Zalacznik-nr-1-do-umowy-Regulamin.pdf"
                          download
                        >
                          Załącznik nr 1 do umowy - Regulamin świadczenia usług dystrybucji energii elektrycznej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/14-Zalacznik-nr-2a-Warunki.pdf"
                          download
                        >
                          Załącznik nr 2a Warunki świadczenie usług dystrybucji energii elektrycznej V VI
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/13-Zalacznik-nr-2b-Warunki.pdf"
                          download
                        >
                          Załącznik nr 2b Warunki świadczenie usług dystrybucji energii elektrycznej II III IV VI V.4
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/12-Zalacznik-nr3-Wykaz-PPE.pdf"
                          download
                        >
                          Załącznik nr 3 Wykaz PPE
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/11-Wzor-umowy-sprzedaz-ee.pdf"
                          download
                        >
                          Wzór umowy sprzedaży energii elektrycznej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/10-ogolne-warunki-sprzedazy-energii.pdf"
                          download
                        >
                          Ogólne warunki sprzedaży energii elektrycznej i świadczenia usług przesyłowych
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/9-umowa_gud.pdf"
                          download
                        >
                          Generalna Umowa Dystrybucyjna
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/8-Zalacznik1.pdf"
                          download
                        >
                          Załącznik nr 1 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/7-Zalacznik2.pdf"
                          download
                        >
                          Załącznik nr 2 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/6-Zalacznik3.pdf"
                          download
                        >
                          Załącznik nr 3 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/5-Zalacznik4.pdf"
                          download
                        >
                          Załącznik nr 4 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/4-Zalacznik5.pdf"
                          download
                        >
                          Załącznik nr 5 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/3-Zalacznik6.pdf"
                          download
                        >
                          Załącznik nr 6 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/2-Zalacznik7.pdf"
                          download
                        >
                          Załącznik nr 7 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://documents.energetyka-wagon.pl/downloads/1-Zalacznik8.pdf"
                          download
                        >
                          Załącznik nr 8 do Generalnej Umowy Dystrybucyjnej
                        </a>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
    data: () => ({
      highlights: [
        'Projekty i architektura',
        'Wykończenia wnętrz',
        'Inwestor zastępczy',
        'Wykonawstwo i nadzory',
        'Marketing',
        'Sprzedaż'
      ],
      experiences: [
        ['mdi-vuejs', '75', 'Vue'],
        ['mdi-vuetify', '100', 'Vuetify'],
        ['mdi-aws', '67', 'AWS'],
        ['mdi-server-security', '95', 'Security']
      ]
    })
  }
</script>
